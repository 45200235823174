(function () {
  'use strict';

  class EditTeachers {
    constructor($mdDialog, $document) {
      let vm = this;
      vm.$mdDialog = $mdDialog;
      vm.body = $document[0].body;
    }

    show(ev, student, callback) {
      const vm = this;
      vm.obj = student;
      vm.$mdDialog.show({
        controller: 'EditTeachersCtrl',
        controllerAs: 'editTeachers',
        templateUrl: 'components/edit-teachers/edit-teachers.tpl.html',
        parent: angular.element(vm.body),
        targetEvent: ev,
        clickOutsideToClose: true
      })
        .then(callback);
    }
    getObj() {
      const vm = this;
      return vm.obj;
    }
  }

  /**
   * @ngdoc service
   * @name components.service:EditTeachers
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('EditTeachers', EditTeachers);
}());
